<template>
  <div>
    <b-row>
      <b-button
        variant="primary"
        class="ml-2 mb-2"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="goBack"
      >Go Back</b-button>
    </b-row>
    <b-row>
      <b-col
        v-if="!show_file_icon"
        cols="12"
        md="8"
        style="min-height: 70vh;"
      >
        <iframe
          v-if="document_type == 'pdf'"
          :src="file_source"
          width="100%"
          height="100%"
          frameborder="0"
        />

        <iframe
          v-else
          :src="file_source"
          width="100%"
          height="100%"
          frameborder="0"
        />
      </b-col>

      <b-col
        v-else
        cols="12"
        md="8"
      >
        <b-card class="b-card p-8">
          <b-row>
            <font-awesome-icon
              icon="exclamation-circle"
              size="10x"
              class="text-danger mx-auto"
              bounce
            />
          </b-row>

          <b-row>
            <p class="font-bold text-center mx-auto">
              We could not find the resume file requested (The applicant has not
              uploaded their resume yet).
            </p>
          </b-row>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-card>
          <b-button
            v-if="!show_file_icon"
            class="mt-2 w-full shadow-lg"
            variant="primary"
            @click="downloadResume"
          >Download Resume</b-button>
          <!-- <hr/>
              <h4>Available Grant(s):</h4>

              <div class="flex mt-4">
                <feather-icon
                  icon="ChevronRightIcon"
                  class="cursor-pointer ml-1"
                ></feather-icon>
                <p class="mt-1">Senior Worker Early Adopter Grant (SW EAG)</p>
              </div> -->
          <hr>
          <h4 class="mt-1">
            Personal Information:
            <span
              v-if="incomplete_profile"
              class="text-danger font-semibold"
            >Incomplete Profile</span>
          </h4>
          <hr>
          <div class="d-flex justify-content-between mt-1">
            <h6>Full Name: {{ applicant_info.fullname }}</h6>
          </div>
          <hr>
          <div class="d-flex justify-content-between mt-1">
            <h6>Date of Birth: {{ applicant_info.date_of_birth }}</h6>
          </div>

          <hr>
          <div class="d-flex justify-content-between mt-1">
            <h6>Email Address: {{ applicant_info.email_address }}</h6>
          </div>
          <hr>

          <div
            v-if="applicant_info.phone_number != 0"
            class="d-flex justify-content-between mt-1"
          >
            <h6>Contact: {{ applicant_info.phone_number }}</h6>
          </div>
          <hr v-if="applicant_info.phone_number != 0">
          <div class="d-flex justify-content-between mt-1">
            <h6>Highest Qualification: {{ applicant_info.highest_qualification }}</h6>
          </div>
          <hr>

          <div
            v-if="
              applicant_info.employment_data &&
                applicant_info.employment_data.length > 0
            "
          >
            <h4 class="mt-1">
              Employment Details:
            </h4>
            <hr>
            <hr>
            <div
              v-for="(item, index) in applicant_info.employment_data"
              :key="index"
              class=""
            >
              <h6 class="mt-1">
                Position: {{ item.position }}
              </h6>
              <h6 class="mt-1">
                Company Name: {{ item.company_name }}
              </h6>
              <h6 class="mt-1">
                Start Date: {{ item.start_date }}
              </h6>
              <h6 class="mt-1">
                End Date: {{ item.end_date }}
              </h6>
              <h6 class="mt-1">
                Job Description:
              </h6>
              <p class="mt-1">
                {{ trimMyString(item.description) }}
              </p>
              <hr>
              <hr>
            </div>
          </div>

          <div
            v-if="
              applicant_info.education_data &&
                applicant_info.education_data.length > 0
            "
          >
            <h4 class="mt-1">
              Education Details:
            </h4>

            <hr> <hr>

            <div
              v-for="(item, index) in applicant_info.education_data"
              :key="index"
              class=""
            >
              <h6 class="mt-1">
                School: {{ item.school_name }}
              </h6>
              <h6 class="mt-1">
                Field of study: {{ item.field_of_study }}
              </h6>
              <h6 class="mt-1">
                Start Date: {{ item.start_date }}
              </h6>
              <h6 class="mt-1">
                End Date: {{ item.end_date }}
              </h6>
              <hr>
              <hr>
            </div>
          </div>
          <h4
            v-if="applicant_info.answers && applicant_info.answers.length > 0"
            class="mt-1"
          >
            Screening Questions:
          </h4>
          <hr
            v-if="applicant_info.answers && applicant_info.answers.length > 0"
          >
          <div
            v-for="(item, index) in applicant_info.answers"
            :key="index"
          >
            <p class="mt-1">
              {{ index + 1 }}. {{ item.question }}:
              <span class="font-bold">{{ item.answer }}</span>
            </p>
          </div>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  data() {
    return {
      document_type: 'pdf',
      file_source: '',
      applicant_info: '',
      show_file_icon: true,
      incomplete_profile: false,
      isLoading: true,
    }
  },

  created() {
    const { booking_id } = this.$route.params
    this.getApplicantInfo(booking_id)
  },

  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getApplicantInfo(booking_id) {
      this.isLoading = true
      const response = await this.$http
        .get(`/api/view-applicant/${booking_id}`)

      if (response.data.success) {
        this.applicant_info = response.data.data
        const item = this.applicant_info
        this.incomplete_profile = item.incomplete_profile
        const company = this.$store.state.auth.ActiveUser.company_name

        // Math.random() is to prevent image being cache and display the previous file, when user upload or changed the resume file
        const file_url = await this.$generateDownloadUrl(`${item.resume_file}?${Math.random(0, 30000000)}`)

        // this.getFile(file_url)
        if (item.resume_file.endsWith('pdf')) {
          this.document_type = 'pdf'
          this.file_source = file_url
        } else {
          this.document_type = 'docs'
          this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${file_url}`
        }

        if (!this.$isEmpty(item.resume_file)) {
          this.show_file_icon = false
        }

        const all_data = { viewed: 'Yes', booking_id: item.booking_id }
        if (item.viewed === 'No' && item.company === company) {
          this.$http
            .put('/api/book', { data: all_data })
            .then(response => {
              const { success } = response.data
            })
            .catch(error => {})
        }
      }
      this.isLoading = false
      // .then(response => {

      // })
      // .catch(error => {
      //   this.$handleErrorResponse(error)
      // })
    },

    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string && string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },

    async downloadResume() {
      const link = document.createElement('a')
      link.href = await this.$generateDownloadUrl(this.applicant_info.resume_file)
      link.setAttribute('download', this.applicant_info.resume_file) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
  },
}
</script>
